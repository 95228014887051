exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reference-tsx": () => import("./../../../src/pages/reference.tsx" /* webpackChunkName: "component---src-pages-reference-tsx" */),
  "component---src-pages-story-tsx": () => import("./../../../src/pages/story.tsx" /* webpackChunkName: "component---src-pages-story-tsx" */),
  "component---src-pages-wp-category-slug-tsx": () => import("./../../../src/pages/{wpCategory.slug}.tsx" /* webpackChunkName: "component---src-pages-wp-category-slug-tsx" */),
  "component---src-templates-reference-tsx": () => import("./../../../src/templates/reference.tsx" /* webpackChunkName: "component---src-templates-reference-tsx" */)
}

